/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter, useHistory} from 'react-router-dom'
import { find } from 'lodash'
import classNames from 'classnames'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Axios from 'axios'
import { Layout, Select, message} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { URL } from '../../../pages/config/index'
import style from './style.module.scss'

const { Sider } = Layout
const { Option,OptGroup } = Select;

const mapStateToProps = ({ menu, settings }) => ({
  menuData: menu.menuData,
  settings,
  flyoutActive:
    (settings.menuType === 'flyout' ||
      settings.menuType === 'compact' ||
      settings.isMenuCollapsed) &&
    !settings.isMobileView,
})

const flyoutTimers = {}
let flyoutItems = {}

const MenuLeft = ({ dispatch, menuData = [], location: { pathname }, settings, flyoutActive },props) => {
  const [activeSubmenu, setActiveSubmenu] = useState('')
  const [activeItem, setActiveItem] = useState('')
  const [renderedFlyoutItems, setRenderedFlyoutItems] = useState({})
  const [newMenuData, setNewMenuData] = useState([])
  

  useEffect(() => {
    getMenuData()
    setActiveItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])
  
  
  let history = useHistory();
  const selectMenu = (e,key) => {
   // console.log(e,key)
     history.push(key.url)

  };

 
  const getMenuData = () => {
    const newToken = localStorage.getItem('Token')
    const roleId = localStorage.getItem('roleId')
    const getUsers = {
      query: `query {
        getMenuList(roleId:"${roleId}")
        {
            title
            key
            url
            icon
            seqNo
            children{
              title
              key
              url
            }
            
        }
        }`,
    }
    Axios({
      url: URL,
      method: 'POST',
      data: getUsers,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${newToken}`,
      },
    }).then(response => {
      if (response.status === 200) {
        const data = response.data.data.getMenuList
        const SortChildMenus = data.sort(function(a, b) {
          return a.seqNo - b.seqNo
        })
        setNewMenuData(SortChildMenus)
      }
    })
  }
  // console.log('newMenuData=============>', newMenuData)

  /* const toggleSettings = e => {
    e.preventDefault()
    const { isSidebarOpen } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: !isSidebarOpen,
      },
    })
  } */

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const toggleMenu = e => {
    e.preventDefault()
    const { isMenuCollapsed } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
  }

  const { isMenuCollapsed } = settings

  const toggleMobileMenu = e => {
    e.preventDefault()
    const { isMobileMenuOpen } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  const handleSubmenuClick = (e, key) => {
    e.preventDefault()
    if (flyoutActive) {
      return
    }
    setActiveSubmenu(activeSubmenu === key ? '' : key)
  }

  const handleFlyoutOver = (event, key, items) => {
    if (flyoutActive) {
      clearInterval(flyoutTimers[key])
      const item = event.currentTarget
      const itemDimensions = item.getBoundingClientRect()
      const element = renderFlyoutMenu(items, key, itemDimensions)
      setRenderedFlyoutItems({
        ...renderedFlyoutItems,
        [key]: element,
      })
      flyoutItems = {
        ...renderedFlyoutItems,
        [key]: element,
      }
    }
  }

  const handleFlyoutOut = key => {
    if (flyoutActive) {
      flyoutTimers[key] = setTimeout(() => {
        delete flyoutItems[key]
        setRenderedFlyoutItems({
          ...flyoutItems,
        })
      }, 100)
    }
  }

  const handleFlyoutContainerOver = key => {
    clearInterval(flyoutTimers[key])
  }

  const renderFlyoutMenu = (items, key, itemDimensions) => {
    const left = `${itemDimensions.left + itemDimensions.width - 10}px`
    const top = `${itemDimensions.top}px`

    return (
      <div
        style={{ left, top }}
        className={classNames(style.air__menuFlyout, {
          [style.air__menuFlyoutLeft]: settings.menuLayoutType === 'left',
          [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark',
          [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
          [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray',
        })}
        key={key}
      >
        <ul
          className={style.air__menuLeft__list}
          onMouseEnter={() => handleFlyoutContainerOver(key)}
          onMouseLeave={() => handleFlyoutOut(key)}
        >
          {items.map(item => {
            return (
              <li
                className={classNames(style.air__menuLeft__item, {
                  [style.air__menuLeft__item__active]: activeItem === item.key,
                })}
                key={item.key}
              >
                <Link to={item.url} className={style.air__menuLeft__link}>
                  {item.icon && <i className={`${item.icon} ${style.air__menuLeft__icon}`} />}
                  <span>{item.title}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const setActiveItems = () => {
    if (!newMenuData.length) {
      return
    }
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const currentItem = find(flattenItems(newMenuData, 'children'), ['url', pathname]) || {}
    const currentSubmenu = newMenuData.reduce((key, parent) => {
      if (Array.isArray(parent.children)) {
        parent.children.map(child => {
          if (child.key === currentItem.key) {
            key = parent
          }
          return ''
        })
      }
      return key
    })
    setActiveItem(currentItem.key)
    setActiveSubmenu(currentSubmenu.key)
  }

  const generateMenuItems = () => {
    const menuItem = item => {
      const { key, title, icon, url } = item
      if (item.category) {
        return (
          <li
            className={style.air__menuLeft__category}
            key={Math.random()}
            style={{ display: 'none' }}
          >
            <span>{title}</span>
          </li>
        )
      }
      return (
        <li
          className={classNames(style.air__menuLeft__item, {
            [style.air__menuLeft__item__active]: activeItem === key,
          })}
          key={key}
        >
          {item.url && (
            <Link to={url} className={style.air__menuLeft__link}>
              {icon && <i className={`${icon} ${style.air__menuLeft__icon}`} />}
              <span>{title}</span>
            </Link>
          )}
          {!item.url && (
            <a href="#" onClick={e => e.preventDefault()} className={style.air__menuLeft__link}>
              {icon && <i className={`${icon} ${style.air__menuLeft__icon}`} />}
              <span>{title}</span>
            </a>
          )}
        </li>
      )
    }

    const submenuItem = item => {
      return (
        <li
          className={classNames(style.air__menuLeft__item, style.air__menuLeft__submenu, {
            [style.air__menuLeft__submenu__active]: activeSubmenu === item.key,
          })}
          key={item.key}
        >
          <a
            href="#"
            className={style.air__menuLeft__link}
            onClick={e => handleSubmenuClick(e, item.key)}
            onMouseEnter={event => handleFlyoutOver(event, item.key, item.children)}
            onFocus={event => handleFlyoutOver(event, item.key, item.children)}
            onMouseLeave={() => handleFlyoutOut(item.key)}
            onBlur={() => handleFlyoutOut(item.key)}
          >
            <i className={`${item.icon} ${style.air__menuLeft__icon}`} />
            <span>{item.title}</span>
            {item.count && (
              <span className="badge text-white bg-blue-light float-right mt-1 px-2">
                {item.count}
              </span>
            )}
          </a>
          <ul className={style.air__menuLeft__list}>
            {item.children.map(sub => {
              if (sub.children) {
                return submenuItem(sub)
              }
              return menuItem(sub)
            })}
          </ul>
        </li>
      )
    }

    return newMenuData.map(item => {
      if (item.children) {
        return submenuItem(item)
      }
      return menuItem(item)
    })
  }

  const items = generateMenuItems()

  return (
    <Sider width="auto">
      <TransitionGroup>
        {Object.keys(renderedFlyoutItems).map(item => {
          return (
            <CSSTransition key={item} timeout={0} classNames="air__menuFlyout__animation">
              {renderedFlyoutItems[item]}
            </CSSTransition>
          )
        })}
      </TransitionGroup>
      <div
        className={classNames(style.air__menuLeft, {
          [style.air__menuLeft__mobileToggled]: settings.isMobileMenuOpen,
          [style.air__menuLeft__toggled]: settings.isMenuCollapsed,
          [style.air__menuLeft__unfixed]: settings.isMenuUnfixed,
          [style.air__menuLeft__shadow]: settings.isMenuShadow,
          [style.air__menuLeft__flyout]: settings.menuType === 'flyout',
          [style.air__menuLeft__compact]: settings.menuType === 'compact',
          [style.air__menuLeft__blue]: settings.menuColor === 'blue',
          [style.air__menuLeft__white]: settings.menuColor === 'white',
          [style.air__menuLeft__gray]: settings.menuColor === 'gray',
          [style.air__menuFlyout__black]:
            settings.flyoutMenuColor === 'dark' && settings.menuType !== 'default',
          [style.air__menuFlyout__white]:
            settings.flyoutMenuColor === 'white' && settings.menuType !== 'default',
          [style.air__menuFlyout__gray]:
            settings.flyoutMenuColor === 'gray' && settings.menuType !== 'default',
        })}
      >
        <div className={style.air__menuLeft__outer}>
          <a
            href="#"
            className={style.air__menuLeft__mobileToggleButton}
            onClick={toggleMobileMenu}
          >
            <span />
          </a>
          <a
            href="#"
            className={style.air__menuLeft__toggleButton}
            onClick={toggleMenu}
            style={{ textAlign: 'end' }}
          >
            {settings.isMenuCollapsed === true ? (
              <img src="resources/images/Menuicon.svg" alt="close" style={{ marginLeft: '25px' }} />
            ) : (
              <img
                src="resources/images/closerIcon.svg"
                alt="closer"
                style={{ marginLeft: '25px' }}
              />
            )}
          </a>
          <a
            href="#"
            onClick={e => e.preventDefault()}
            className={style.air__menuLeft__logo}
            style={{ paddingLeft: '8px' }}
          >
            <div
              className={style.air__menuLeft__logo__name}
              style={{ fontSize: '15px', marginLeft: '0px', fontWeight: '500' }}
            >
              Service Management
            </div>
          </a>
          <a
            href="#"
            id="select-search"
            onClick={e => e.preventDefault()}
            className={style.air__menuLeft__logo1}
            style={{ paddingLeft: '4%', paddingRight: '4%', marginTop: '-4%' }}
          >
            <Select
              showSearch
              allowClear
              showArrow={false}
              value={null}
              style={{ width: '100%', borderRadius: '0px' }}
              placeholder={
                <React.Fragment>
                  <SearchOutlined />
                  &nbsp; <span style={{ color: '#959595' }}>Search</span>
                </React.Fragment>
              }
              onSelect={selectMenu}
              
            >
            {newMenuData.map((menu) =>

                  menu.children ? (
                    <OptGroup label={<span style={{ fontSize: '13px', color: 'black' }}><strong>{menu.title}</strong></span>}>
                     {  menu.children.map((subMenuItem) =>
                      subMenuItem.children ? (
                        subMenuItem.children.map((lowerSubMenuItem) => (
                          <Option  key={lowerSubMenuItem.key} value={`${lowerSubMenuItem.title}`} url={`${lowerSubMenuItem.url}`}>
                            {lowerSubMenuItem.title}
                          </Option>
                        ))
                      ) : (
                        <Option key={subMenuItem.key} value={`${subMenuItem.title}` }  url={`${subMenuItem.url}`}>
                          {subMenuItem.title}
                        </Option>
                      )
                    )
                  }
                    </OptGroup>
                  ):(
                    <Option  key={menu.key} value={`${menu.title}`} url={`${menu.url}`} >
                      {menu.title}
                    </Option>
                  )
                )}
          </Select>
      
          </a>
          <a href="#" style={{ textAlign: 'center', paddingBottom: '0.6rem' }}>
            {settings.isMenuCollapsed === true ? (
              <img
                src="resources/images/search.svg"
                alt="close"
                style={{ height: '17px', width: '17px' }}
              />
            ) : (
              ''
            )}
          </a>
          <PerfectScrollbar>
            <div id="menu-left-container" className={style.air__menuLeft__container}>
              <ul className={style.air__menuLeft__list}>{items}</ul>
            </div>
          </PerfectScrollbar>
          {isMenuCollapsed === true ? (
            <span
              style={{ textAlign: 'center', marginBottom: '20px', cursor: 'pointer' }}
              role="presentation"
              onClick={logout}
            >
              <img
                src="resources/images/logout.svg"
                alt="logout"
                style={{ width: '19px', height: '19px' }}
              />
            </span>
          ) : (
            <span
              style={{
                textAlign: 'left',
                display: 'flex',
                marginBottom: '20px',
                padding: '0.6rem .66rem 0.6rem .66rem',
                cursor: 'pointer',
              }}
              role="presentation"
              onClick={logout}
            >
              <img
                src="resources/images/logout.svg"
                alt="logout"
                style={{ width: '19px', height: '19px' }}
              />{' '}
              &nbsp;<span style={{ color: 'white', fontSize: '14px' }}>Logout</span>
            </span>
          )}
        </div>
      </div>
      <a href="#" className={style.air__menuLeft__backdrop} onClick={toggleMobileMenu} />
    </Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
