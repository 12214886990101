/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Popover,Button,Dropdown } from 'antd'
import moment from 'moment'
import { getCoWorkOrders } from '../../../pages/apiServices/index'
/* import Search from './Search'
import IssuesHistory from './IssuesHistory'
import Status from './Status'
import LanguageSwitcher from './LanguageSwitcher'
import Actions from './Actions'  */

import UserMenu from './UserMenu'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  settings,
})

const TopBar = () => {
  const [workOrdersData, setWorkOrdersData] = useState([])
  const [count,setCount] = useState(null)

  const history = useHistory()

  useEffect(() => {
    getnotifications()
  }, [])

  const getnotifications = async () => {
    const response = await getCoWorkOrders()
    if (response !== null) {
      setCount(response[0].count)
      setWorkOrdersData(response[0].coWorkOrders)
    }
  }
  const getHome = () => {
    history.push('/home')
  }

  const menus = (
    <PerfectScrollbar options={{ suppressScrollX: '200px' }}>
      <div className="card" style={{ borderRadius: '0px',border:'none', height: '65vh',width:'300px', marginBottom: '0px' }}>
        <div className="card-body" style={{ padding: '0px' }}>
          {workOrdersData.length >0 ? workOrdersData.map(data => (
            <div
              className="card"
               style={{
                borderRadius: '0px',
                cursor: 'pointer',
                height: '20.5vh',
                margin: '5px',
                borderLeft:
                  data.status.listName === 'Assigned'
                    ? '4px solid #696969'
                    : data.status.listName === 'Pending' || data.status.listName === 'InProgress'
                    ? '4px solid #FFA500'
                    : '4px solid #1DB02A',
                borderTop: 'none',
                borderRight: 'none',
              }}
            >
              <div className="card-body" style={{ padding: '5px' }}>
                <div className="row">
                  <div className="col-lg-5">
                    <h6 style={{ fontWeight: '600', fontSize: '11px' }}>Order No:</h6>
                  </div>
                  <div className="col-lg-7" style={{ marginTop: '-5px' }}>
                    <span style={{ fontSize: '11px' }}>{data.orderNo}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <h6 style={{ fontWeight: '600', fontSize: '11px' }}>Customer Name:</h6>
                  </div>
                  <div className="col-lg-7" style={{ marginTop: '-5px' }}>
                    <span style={{ fontSize: '11px',width:'150px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{data.customers.name}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <h6 style={{ fontWeight: '600', fontSize: '11px' }}>Order Date:</h6>
                  </div>
                  <div className="col-lg-7" style={{ marginTop: '-5px' }}>
                    {data.scheduleDate === null || data.scheduleDate === undefined
                        ? null
                        : moment(data.scheduleDate).format('YYYY-MM-DD')}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <h6 style={{ fontWeight: '600', fontSize: '11px' }}>Scheduled Date:</h6>
                  </div>
                  <div className="col-lg-7" style={{ marginTop: '-5px' }}>
                    <span style={{ fontSize: '11px' }}>
                      {data.scheduleDate === null || data.scheduleDate === undefined
                        ? null
                        : moment(data.scheduleDate).format('YYYY-MM-DD')}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <h6 style={{ fontWeight: '600', fontSize: '11px' }}>Scheduled Time:</h6>
                  </div>
                  <div className="col-lg-7" style={{ marginTop: '-5px' }}>
                    <span style={{ fontSize: '11px' }}>{data.scheduleTime}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <h6 style={{ fontWeight: '600', fontSize: '11px' }}>Status:</h6>
                  </div>
                  <div className="col-lg-7" style={{ marginTop: '-5px' }}>
                  <span style={{ fontSize: '11px' }}>{data.status.listName}</span>
                  </div>
                </div>
              </div>
            </div>
          )):null}
        </div>
      </div>
    </PerfectScrollbar>
  )

  return (
    <div className={style.topbar}>
      <div className="mr-md-4 mr-auto">
        {/* {settings.settings.isMenuCollapsed === true ? */}
        <img
          src="resources/images/n-a.png"
          alt="logo"
          width="80%"
          role="presentation"
          style={{ cursor: 'pointer' }}
          onClick={getHome}
        />
        {/* : ''} */}
        {/*  <Search /> */}
      </div>
      <div className="mr-auto d-none d-md-block">{/*  <IssuesHistory /> */}</div>
      <div className="mb-0 mr-4 d-xl-block d-none">{/*  <Status /> */}</div>
      <div className="mr-4 d-none d-sm-block">{/* <LanguageSwitcher /> */}</div>
      <div className="mr-4 d-none d-sm-block">
          <Popover bodyStyle={{padding:'0px'}} content={menus} title="" trigger="click">
            <span style={{cursor:'pointer'}}>
              <img src="resources/images/notification.svg" alt="notification" />
            <span style={{ color: 'red',fontSize:'17px' }}>{count}</span>
            </span>
          </Popover>
      </div>
      <div className="">
        <UserMenu />
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(TopBar))
