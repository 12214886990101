export default async function getMenuData() {
  return [
    // {
    //   category: true,
    //   title: 'Equipments',
    // },
    // {
    //   title: 'Equipments',
    //   key: 'd1',
    //   url: '/dashboard/analytics',
    //   icon: 'fa fa-wrench',
    //   children: [
    //     {
    //       title: 'Equipment',
    //       key: 'eq1',
    //       url: '/equipment',
    //     },
    //     {
    //       title: 'Spare Part',
    //       key: '2',
    //       url: '/spare-parts',
    //     },
    //     {
    //       title: 'Brand',
    //       key: '3',
    //       url: '/brand',
    //     },
    //     {
    //       title: 'Classification',
    //       key: '4',
    //       url: '/classification',
    //     },
    //   ],
    // },
    // {
    //   title: 'Customers',
    //   key: 'cus1',
    //   url: '/customer',
    //   icon: 'fa fa-user',
    //   children: [
    //     {
    //       title: 'Customers',
    //       key: '5',
    //       url: '/customer',
    //     },
    //   ],
    // },
    // {
    //   title: 'Work Orders',
    //   key: '6',
    //   // url: '/dashboard/ecommerce',
    //   icon: 'fa fa-edit',
    //   children: [
    //     {
    //       title: 'Work Order',
    //       key: '7',
    //       url: '/work-order',
    //     },
    //     {
    //       title: 'Work Order Calender',
    //       key: '8',
    //       url: '/wo-calender',
    //     },
    //     {
    //       title: 'Work Order Map',
    //       key: '9',
    //       url: '/wo-map',
    //     },
    //   ],
    // },
    // {
    //   title: 'Requests',
    //   key: '10',
    //   url: '/dashboard/crypto',
    //   icon: 'fa fa-headphones',
    //   children: [
    //     {
    //       title: 'Spare Parts Request',
    //       key: '11',
    //       url: '/sparepartreq',
    //     },
    //     {
    //       title: 'Quotation Request',
    //       key: '12',
    //       url: '/quotationreq',
    //     },
    //     {
    //       title: 'Reschedule Request',
    //       key: '13',
    //       url: '/reschedulereq',
    //     },
    //     {
    //       title: 'Feedback Request',
    //       key: '14',
    //       url: '/feedbackreq',
    //     },
    //   ],
    // },
    // {
    //   title: 'Reports',
    //   key: '15',
    //   url: '/dashboard/crypto-terminal',
    //   icon: 'fa fa-bar-chart',
    //   children: [
    //     {
    //       title: 'Work Order Summary',
    //       key: '16',
    //       url: '/wo-summary',
    //     },
    //     {
    //       title: 'Equipment Report',
    //       key: '17',
    //       url: '/equipment-report',
    //     },
    //     {
    //       title: 'Warranty Information Report',
    //       key: '18',
    //       url: '/wi-report',
    //     },
    //     {
    //       title: 'Spare Parts Inventory Report',
    //       key: '19',
    //       url: '/spi-report',
    //     },
    //   ],
    // },
    // {
    //   title: 'Setup',
    //   key: '20',
    //   url: '/dashboard/jira',
    //   icon: 'fa fa-cog',
    //   children: [
    //     {
    //       title: 'Users',
    //       key: '21',
    //       url: '/users',
    //     },
    //     {
    //       title: 'Roles & Access',
    //       key: '22',
    //       url: '/roles',
    //     },
    //     {
    //       title: 'E-mail Templates',
    //       key: '23',
    //       url: '/email-template',
    //     },
    //     {
    //       title: 'Field Service Engineer Group',
    //       key: '24',
    //       url: '/fseg',
    //     },
    //     {
    //       title: 'Workflow Setup',
    //       key: '25',
    //       url: '/workflow-setup',
    //     },
    //     {
    //       title: 'Work Order Type',
    //       key: '26',
    //       url: '/workorder-type',
    //     },
    //     {
    //       title: 'Warehouse',
    //       key: '27',
    //       url: '/warehouse',
    //     },
    //     {
    //       title: 'Business Unit',
    //       key: '28',
    //       url: '/business-unit',
    //     },
    //     {
    //       title: 'Skills',
    //       key: '29',
    //       url: '/skills',
    //     },
    //     {
    //       title: 'Price List',
    //       key: '37',
    //       url: '/price-list',
    //     },
    //     {
    //       title: 'Tax Category',
    //       key: '38',
    //       url: '/tax-category',
    //     },
    //     {
    //       title: 'Tax',
    //       key: '39',
    //       url: '/tax',
    //     },
    //   ],
    // },
    // {
    //   title: 'Sales',
    //   key: '21',
    //   // url: '/dashboard/ecommerce',
    //   icon: 'fa fa-edit',
    //   children: [
    //     {
    //       title: 'Sales Orders',
    //       key: '34',
    //       url: '/sales-order',
    //     },
    //     {
    //       title: 'Sales Invoice',
    //       key: '31',
    //       url: '/sales-invoice',
    //     },
    //     {
    //       title: 'Goods Shipment',
    //       key: '32',
    //       url: '/goods-shipment',
    //     },
    //     // {
    //     //   title: 'Goods Receipt',
    //     //   key: '33',
    //     //   url: '/goods-receipt',
    //     // },
       
    //   ],
    // },
    // {
    //   title: 'Purchase',
    //   key: '22',
    //   // url: '/dashboard/ecommerce',
    //   icon: 'fa fa-edit',
    //   children: [
    //     {
    //       title: 'Purchase Orders',
    //       key: '35',
    //       url: '/purchase-orders',
    //     },
    //     {
    //       title: 'Purchase Invoice',
    //       key: '36',
    //       url: '/purchase-invoice',
    //     },
    //     {
    //       title: 'Goods Receipt',
    //       key: '33',
    //       url: '/goods-receipt',
    //     },
    //   ],
    // },
  ]
}