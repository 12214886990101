import axios from "axios";
import { URL } from "../config";

let localToken;
const customInstance = axios.create();
customInstance.defaults.baseURL = URL;
customInstance.defaults.method = "POST";
customInstance.defaults.headers.post["Content-Type"] = "application/json";


customInstance.interceptors.request.use(
    (config) => {
      if (!localToken) {
        localToken = localStorage.getItem('Token')
      }
      config.headers.Authorization = `bearer ${localToken}`;
      return config;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );



  const getSupplierCategoeryData  =  async (id) =>{
    try{
      const getSupplierCData = await customInstance({
        data: {
          query: `query {getSupplierCategory(bUnit:null, searchKey:null,  categoryId:${id === undefined || id === null ? null :`"${id}"`})    
            {
             supplierCategoryId
                bUnit{
               bUnitId
              name
           }
               name
               value
                isDefault
               isActive
               window {
              windowsId
              name
              }
               createdBy{
              userId
              username
          }
              updatedBy{
              userId
              username
          }
              createdDate
              updatedDate
      }
      }
      `,
        },
      });
      return getSupplierCData.data.data.getSupplierCategory
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getBusinessUnitAllData = async () =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {  getBusinessUnit(bUnitId : null) {
            bUnitId
            name
            }
          }
      `,
        },
      });
      return bunitData.data.data.getBusinessUnit
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getServiceRendered = async (id) =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {
            getservrendered (bUnitId : null
                             servrenderedId: ${id === null || id === undefined ? null : `"${id}"`}) {
               servrenderedId
               bUnit{
                   bUnitId
                   name
               }
               client{
                   clientId
                   name
               }
               key
               name
               createdDate
               updatedDate
               createdBy{
                   userId
                   username
               }
               updatedBy{
                   userId
                   username
               }
             }
        }                
      `,
        },
      });
      return bunitData.data.data.getservrendered
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getServiceRenderedTypesData = async (id) =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {
            getservrenderedTypes (servrenderedId: "${id}") {
                servrenderedTypeId
               servrendered{
                   servrenderedId
                   name
               }
               bUnit{
                   bUnitId
                   name
               }
               client{
                   clientId
                   name
               }
               key
               name
               createdDate
               updatedDate
               createdBy{
                   userId
                   username
               }
               updatedBy{
                   userId
                   username
               }
             }
        }                        
      `,
        },
      });
      return bunitData.data.data.getservrenderedTypes
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getdefectsData = async (id) =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {
            getDefects (bUnitId : null,
                             defectsId: ${id === undefined || id === null ? null : `"${id}"`}) {
               defectsId
               bUnit{
                   bUnitId
                   name
               }
               client{
                   clientId
                   name
               }
               key
               name
               createdDate
               updatedDate
               createdBy{
                   userId
                   username
               }
               updatedBy{
                   userId
                   username
               }
             }
        }                                
      `,
        },
      });
      return bunitData.data.data.getDefects
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getDefectsTypesData = async (id) =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {
            getDefectTypes (defectsId: "${id}") {
               defectsTypeId
               defects{
                   defectsId
                   name
               }
               bUnit{
                   bUnitId
                   name
               }
               client{
                   clientId
                   name
               }
               key
               name
               createdDate
               updatedDate
               createdBy{
                   userId
                   username
               }
               updatedBy{
                   userId
                   username
               }
             }
        }                                
      `,
        },
      });
      return bunitData.data.data.getDefectTypes
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getsupplierData = async (id) =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {getSupplier( supplierId:${id === undefined || id === null ? null :`"${id}"` }, bUnit:null, searchKey:null,  categoryId:null)    
          {
           supplierId
              bUnit{
             bUnitId
            name
            }
             name
             value
            supCategory {
            supplierCategoryId
            name
            }
            supplierAddress{
                supplierAddressId
                fullAddress
            }
            priceList {
            priceListId
            name
            }
             gstNo
              paymentMethod {
            paymentMethodId
            name
            }
              paymentTerm {
            paymentTermId
            name
            }
    
              referenceList {
            referenceListId
            listName
            }
             address1
             address2
             address3
             city
              region{
            regionId
            name
            }
              country {
            countryId
            name
            }
             postalCode
              phoneNo
             contactName
             email
             isActive
             window {
            windowsId
            name
            }
             createdBy{
            userId
            username
        }
            updatedBy{
            userId
            username
        }
            createdDate
            updatedDate
    }
    }                                    
      `,
        },
      });
      return bunitData.data.data.getSupplier
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getPriceListData = async () =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {
            getPriceList (bUnitId: null,priceListId:null,isSales:"Y") {
              priceListId
              name
              referenceList {
                referenceListId
                listName
                listKey
                }    
          }
        }`,
        },
      });
      return bunitData.data.data.getPriceList
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getPaymentMethodsData = async () =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {
            getPaymentMethod (bUnit: null,paymentMethod:null) {
              paymentMethodId 
                  bUnit{
                       bUnitId
                       name
                       }
                name
                isCash 
                isActive 
                window {
                       windowsId
                       name
                       }
                isDefault 
                isPurchase
                isSales 
                message
                createdBy{
                       userId        
                       username
                       }
                updatedBy {
                       userId        
                       username
                       }
                createdDate
                updatedDate  
           }
        }`,
        },
      });
      return bunitData.data.data.getPaymentMethod
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getPaymentTermsData = async () =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {
            getPaymentTerm(bUnit: null , paymentTermId:null)
            {
                    paymentTermId
                bUnit{
                    bUnitId
                    name
                    }
            
                      value 
                     name 
                     description 
                     isDefault 
                     isPurchase 
                     isSales 
                      window {
                            windowsId
                            name
                            }
                     isActive 
                     createdDate 
                     updatedDate 
            
            
                    createdBy{
                        userId
                        username
                    }
                    updatedBy{
                        userId
                        username
                    }
            
                 }          
        }`,
        },
      });
      return bunitData.data.data.getPaymentTerm
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getCurrencyData = async () =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {
            getReferenceTypes (bunitId : null,  moduleId: null,
            referenceTypeId: "2E56A897D005462BB3366C7696E458D5") {
                   referenceTypeId
                   name
                   key
                   bUnit{
                   bUnitId
                   name
                   }
                   module{
                    moduleId
                   name
                   }
                 referenceList 
                 {
                 referenceListId
                 referenceTypeId
                 referenceName
                 listName
                 listKey
                 }
                 }       
        }`,
        },
      });
      return bunitData.data.data.getReferenceTypes
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getCountryData = async () =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {getCountry(countryId:null,bUnit:null)    
            {
              countryId
               bUnit{
              bUnitId
              name
              }
      
               referenceList{
              referenceListId
             listName
              }
               code
              name
              description
              window{
              windowsId
              name
              }
              createdBy{
              userId
              username
              }
               updatedBy{
      
              userId
              username
              }
              createdDate
              updatedDate
      
         regionList{
              regionId
       bUnit{
              bUnitId
              name
              }
      
       country{
              countryId
              name
              }
       value
      name
      stdPrecision
      costPrecision
       symbol
      description
      createdBy{
              userId
              username
              }
       updatedBy{
              userId
              username
              }
      createdDate
      updatedDate
            }    
            }}`,
        },
      });
      return bunitData.data.data.getCountry
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getRegionData = async () =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {getRegion(regionId:null,bUnit:null)    
            {
              regionId
       bUnit{
              bUnitId
              name
              }
      
       country{
              countryId
              name
              }
       value
      name
      stdPrecision
      costPrecision
       symbol
      description
      createdBy{
              userId
              username
              }
       updatedBy{
      
              userId
              username
              }
      createdDate
      updatedDate
      
                 
            }}
      `,
        },
      });
      return bunitData.data.data.getRegion
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getSupplierAddressLinesData = async (id) =>{
    try{
      const bunitData = await customInstance({
        data: {
          query: `query {getSupplierAddress(bUnit:null, supplier:"${id}",  supaddressId:null)    
          {
           supplierAddressId
              bUnit{
             bUnitId
            name
         }
            isInvoiceAdd
            fax
            fullAddress
            supplier{
            supplierId
            name
            }
            
        region{
        regionId
            name
            }
              country {
            countryId
            name
            }
             postalCode
              phoneNo
             contactName
             city
             email
             isActive
            addressLine1
             addressLine2
             addressLine3
             createdBy{
            userId
            username
        }
            updatedBy{
            userId
            username
        }
            createdDate
            updatedDate
    }
    }    
      `,
        },
      });
      return bunitData.data.data.getSupplierAddress
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getUOMList = async () =>{
    try{
      const uomData = await customInstance({
        data: {
          query: `query {
            getUom (bUnitId : null,
                             uomId: null) {
               uomId
               bUnitId{
                   bUnitId
                   name
               }
               
               ediCode
               name
               description
               stdPrecision
               costPrecision
               symbol
               isDecimal
               isActive
               createdDate
               updatedDate
               createdBy{
                   userId
                   username
               }
               updatedBy{
                   userId
                   username
               }
             }
        }
        `,
        },
      });
      return uomData.data.data.getUom
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getBatchGroupData = async () =>{
    try{
      const uomData = await customInstance({
        data: {
          query: `query {
            getBatchGroup( batchGroupId :null ,  bUnitId:null ){ 
            batchGroupId
            bUnitId  {
           bUnitId
           name
           }
           name
           isActive
           showInStock
           isManual
           createdBy {
           userId
           lastName
           }
           updatedBy {
           userId
           lastName
           }
           windowId{
               windowsId
               name
           }
               
           createdDate
           updatedDate
                
            }
           }
        `,
        },
      });
      return uomData.data.data.getBatchGroup
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getSalesOrderData = async (value,recordOffset) =>{
    try{
      const salesOrderData = await customInstance({
        data: {
          query: `query {getSOrder(sorderId:null,bUnit:null, documentNo:null,fromDate:null,toDate:null,customerId:null,limit:${value},from:${recordOffset === null || recordOffset === undefined ? 0 :recordOffset})    
          {
            sorderId
     bUnit{
            bUnitId
            name
            }
    pendingLines
    invoiceStatus
    deliveryStatus
    documentNo
    docType{
      docTypeId
      name
    }
    createddate
    customer{
            customerId
            name
            }
    priceList{
            priceListId
            name
            }
    warehouse{
            warehouseId
            name
  
            }
    paymentMethod{
      paymentMethodId
      name
    }
    quotation{
      quotationId
      quotationNo
  }    
    referenceList{
      referenceListId
      listName
      }
    }     
    }`,
        },
      });
      return salesOrderData.data.data.getSOrder
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getGoodsShipmentData = async (value) =>{
    try{
      const GoodsShipmentData = await customInstance({
        data: {
          query: `query {getShipment(shipmentId:null, bUnit:null, documentNo:null, fromDate:null, toDate:null, customerId:null,limit:${value},from:0 )    
            {
              shipmentId
                bUnit{
                 bUnitId
                  name
                      }
                documentNo
                customer{
                      customerId
                      name
                      }
                customerAddress{
                 customerAddressId
                      }
                referenceList{
                  referenceListId
                      listName
                      }
                warehouse{
                       warehouseId
                      name
              }        
                remarks
                processShipment
                post
                processed
                processing
              docType{
              docTypeId
              name
              }
              refNo
              trnNo  
      sOrder{
                     sorderId
                     documentNo
       }
      paymentMethod{
        paymentMethodId
         name
      }
              createdby{
      
              userId
              username
              }
       updatedby{
              userId
              username
              }
              quotation{
                quotationId
                quotationNo
            } 
      createddate
      updateddate
            shipmentLine{
                 shipmentLineId
                       createdby{
      
              userId
              username
              }
       updatedby{
              userId
              username
              }
      createddate
      updateddate
               line
               equipment{
                      equipmentId
                      name
              }
               sparapart{
                      sparepartId
                      name
                      }
               qty
               storagebin{
                      storagebinId
                      name
                 }
            }
            }
            }`,
        },
      });
      return GoodsShipmentData.data.data.getShipment
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getPurchaseOrderData = async (value,recordOffset) =>{
    try{
      const purchaseData = await customInstance({
        data: {
          query: `query {getPOrder(pOrderId:null,bUnit:null, supplier:null,fromDate:null,toDate:null,limit:${value},from:${recordOffset === null || recordOffset === undefined ? 0 :recordOffset})    
          {
            pOrderId
     bUnit{
            bUnitId
            name
            }
    orderdate				
     documentNo
     supplier{
      supplierId
      name
      }
    priceList{
            priceListId
            name
            }
     referenceList{
            referenceListId
            listName
            }
            paymentMethod {
              paymentMethodId
              name
              }
     docType{
            docTypeId
            name
            }
    
     warehouse{
            warehouseId
            name
    
            }
    paymentTerm{
      paymentTermId
       name
    }
     orderRefNo
    deliveryNote 
    description
    processed
    processing
    isInvoiced
    isReceived
    docStatus{
    referenceListId
            listName
    }
    invoiceStatus{
    referenceListId
            listName
    }
    isReturn
    netTotal
     grossTotal
    taxAmt
     disAmt
    processed
     processing
     scheduledDate
     isTaxIncluded
     termsconditions
     address{
         supplierAddressId
         fullAddress
     }
    createdBy{
            userId
            username
            }
     updatedBy{
            userId
            username
            }
    createdDate
    updatedDate  
     }
    }`,
        },
      });
      return purchaseData.data.data.getPOrder
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getPInvoiceData = async (value) =>{
    try{
      const PInvoiceData = await customInstance({
        data: {
          query: `query{
            getPInvoice(pInvoice:null, documentNo:null,supplierId:null,bUnit:null,
                  fromDate:null, toDate:null,from:0,limit:${value}){
              bUnit{
                  bUnitId
                  name
              }
              pInvoiceId
            
            docType{
            docTypeId
            name
            }
            documentNo
            orderDate
            supplier{
            supplierId
            name
            }
            priceList{
            priceListId
            name
                       isTaxIncluded
            }
            paymentTerm{
            paymentTermId
            name
            }
            warehouse{
            warehouseId
            name
            }
            orderNo
            deliveryNote
            description
            processed
            processing
            netTotal
            grossTotal
            taxAmount
            paymentMethod{
            paymentMethodId
            name
            }
            order{
            pOrderId
            documentNo
            }
            supplierAddress{
            supplierAddressId
            fullAddress
            }
            orderRefNo
            reference{
              referenceListId
              listName
              }
              recieveStatus{
            referenceListId
            listName
            listKey
                }
                invoiceStatus{
            referenceListId
            listName
            listKey
                }
                docStatus{
            referenceListId
            listName
            listKey
            }        
              createdBy{
                  userId
                  username
              },
              updatedBy{
                  userId
                  username
              }
              createdDate
              updatedDate
              
            }
          }`,
        },
      });
      return PInvoiceData.data.data.getPInvoice
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getWOInfoData = async () =>{
   const userid = window.localStorage.getItem('userId')
    try{
      const PInvoiceData = await customInstance({
        data: {
          query: `query {
            getWOInfo(type:"B284739E54CF455EAD668F8CF0FF4945",user:"${userid}" ) 
            {
            workordersId
            orderNo
            orderDate
            orderType{
                workOrderTypeId
                name
            }
            status{
                referenceListId
                listName
            }
            remark
            scheduleDate
            scheduleTime
            customers{
                customerId
                name
                code
            }
            updated
            created
            completedWorkOrders
            {
            workordersId
            orderNo
            orderDate
            orderType{
                workOrderTypeId
                name
            }
            status{
                referenceListId
                listName
            }
            remark
            scheduleDate
            scheduleTime
            customers{
                customerId
                name
                code
            }
            updated
            created
            }
            scheduledateWorkorders{workordersId
            orderNo
            orderDate
            orderType{
                workOrderTypeId
                name
            }
            status{
                referenceListId
                listName
            }
            remark
            scheduleDate
            scheduleTime
            customers{
                customerId
                name
                code
            }
            updated
            created}
            }
            }`,
        },
      });
      return PInvoiceData.data.data.getWOInfo
    }catch(error){
      console.error(JSON.stringify(error, null, 2));
      return null;
    }
  }

  const getCoWorkOrders = async () =>{
    const userid = window.localStorage.getItem('userId')
     try{
       const PInvoiceData = await customInstance({
         data: {
           query: `query {
            getCOworkorders(user:"${userid}")
            {
        count
        coWorkOrders{
            workordersId
            orderNo
            scheduleDate
            scheduleTime
            customers{
                customerId
                name
                code
                }
            worderDetails{
            startTime
            endTime
            woStartTime
            woEndTime
            }
            status{
              referenceListId
              listName
            }
            updatedBy{
            userId
            lastName
            }
            updated
            technicianTravelTime
                 }
        }
    }
    `,
         },
       });
       return PInvoiceData.data.data.getCOworkorders
     }catch(error){
       console.error(JSON.stringify(error, null, 2));
       return null;
     }
   }

  export {
    getSupplierCategoeryData,
    getBusinessUnitAllData,
    getServiceRendered,
    getServiceRenderedTypesData,
    getdefectsData,
    getDefectsTypesData,
    getsupplierData,
    getPriceListData,
    getPaymentMethodsData,
    getPaymentTermsData,
    getCurrencyData,
    getCountryData,
    getRegionData,
    getSupplierAddressLinesData,
    getUOMList,
    getBatchGroupData,
    getSalesOrderData,
    getGoodsShipmentData,
    getPurchaseOrderData,
    getPInvoiceData,
    getWOInfoData,
    getCoWorkOrders
  }