import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

const Footer = ({ settings: { isContentMaxWidth} }) => {
  return (
    <div
      className={classNames(style.footer, {
        [style.footerFullWidth]: !isContentMaxWidth,
      })}
    >
      <div className={style.inner}>
        <div className="row">
          <div className="col-md-8">
            <p className="mb-0" style={{fontSize:'12px'}}>
              Copyright © 2017-{new Date().getFullYear()} N & A {' '}
            </p>
          </div>
          <div className="col-md-4" />      
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
