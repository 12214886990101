
/* eslint-disable */

// import firebase from 'firebase/app'
import { notification } from 'antd'
// import 'firebase/auth'
// import 'firebase/database'



const firebaseConfig = {
  apiKey: 'AIzaSyBJVhr2WZshEGR7egcxoygQIphKOkKVIYQ',
  authDomain: 'sellpixels-7d5d4.firebaseapp.com',
  databaseURL: 'https://sellpixels-7d5d4.firebaseio.com',
  projectId: 'sellpixels-7d5d4',
  storageBucket: 'cleanui-72a42.appspot.com',
  messagingSenderId: '338219933237',
}

// firebase.initializeApp(firebaseConfig)
// export const firebaseAuth = firebase.auth()
// export const firebaseDatabase = firebase.database()

export async function login() {
  // return firebaseAuth()
  // return firebaseAuth
  //   .signInWithEmailAndPassword(email, password)
  //   .then(() => true)
  //   .catch(error => {
  //     notification.warning({
  //       message: error.code,
  //       description: error.message,
  //     })
  //   })
//   const getConversionUrl =`${Auth}?grant_type=password&username=${email}&password=${password}`   
//  await axios({
//     method:'POST',  
//     url: getConversionUrl,
//   headers: {
//      'Content-Type': ["application/x-www-form-urlencoded", "application/x-www-form-urlencoded"],
//      'Authorization':'Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ='
//       },  
//    }).then((response) => {
//      console.log("response from token============>",response)
//     if(response.data !=='' || response.data!==undefined || response.data!==null){
//     const client = new ApolloClient({
//       uri:URL,
//       request: operation => {
//         operation.setContext({
//           headers: {
//             authorization: `bearer ${response.data.access_token}`,
//           },
//         });
//       },
//     });

//     const GET_USERLIST = gql`query {
//       userLogin(user:"${email}"){
//           userId
//           bUnit{
//           bUnitId
//           name
//           }
//           warehouse{
//           warehouseId
//           name
//           }
//           role{
//               roleId
//               roleName
//           }
//           username
//           access{
//           accessId
//           module{
//           moduleId
//           name
//           }
//           type
//           name
//           view
//           write
//           delete
//           download      
//           }
//       }
//   }`;
//       client
//         .query({
//           query: GET_USERLIST,
//         })
//         .then(result => {
//           console.log("flag above to call service in result======>",flag)
//          const newResult = result.data.userLogin
//          if(newResult !=='' || newResult !==undefined || newResult!==null){
//           console.log("result in get user============>",newResult)
//           flag = true;
//           window.localStorage.setItem('flag', flag);
//           window.localStorage.setItem('Token', response.data.access_token);
//           window.localStorage.setItem('email', newResult.username);
//           window.localStorage.setItem('roleName', newResult.role.roleName,);
//           // window.localStorage.setItem('User',  getUserList.user);
//          }
//          console.log("flag in new result=====>",flag)
//          return flag;
//         }).catch(err => {
//           const error = JSON.parse(JSON.stringify(err));
//           const { message } = error;
//           if(message === "Network error: Unexpected token < in JSON at position 0"){
//             history.push('/auth/login')
//           }
//       })

//      }else{
//         notification.warning({
//         message: "Error",
//           description: "Invalid Login Credentials!!",
//        }) 
//      }  
//    }).catch((e) => {
//     if(e.response.status===400 || e.response.status===401 ){
//       notification.warning({
//         message:  e.response.data.error_description,
//         description: "Check UserName Or Password",
//        }) 
//     }
//     if(e.response.status===500){
//       notification.warning({
//         message: "Network Error",
//         description:"Contact To Infra Team",
//        }) 
//     }
//   });
//   console.log("flag to return===================>",flag)
//  console.log("flag to return from local",window.localStorage.getItem('flag'))
//   // return flag;

}

export async function register(email, password, name) {
  return firebaseAuth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        firebaseDatabase
          .ref('users')
          .child(uid)
          .set({
            role: 'admin',
            name,
          })
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

// export async function currentAccount() {
//   let userLoaded = false
//   function getCurrentUser(auth) {
//     return new Promise((resolve, reject) => {
//       if (userLoaded) {
//         resolve(firebaseAuth.currentUser)
//       }
//       const unsubscribe = auth.onAuthStateChanged(user => {
//         userLoaded = true
//         unsubscribe()
//         const getUserData = async () => {
//           if (user) {
//             const userFields = await firebaseDatabase
//               .ref('users')
//               .child(user.uid)
//               .once('value')
//               .then(snapshot => {
//                 return snapshot.val()
//               })
//             const mergedUser = Object.assign(user, {
//               id: user.uid,
//               name: userFields.name,
//               role: userFields.role,
//               avatar: user.photoUrl,
//             })
//             return mergedUser
//           }
//           return user
//         }
//         resolve(getUserData())
//       }, reject)
//     })
//   }
//   return getCurrentUser(firebaseAuth)
// }

export async function currentAccount() {
  const obj = {
    email:window.localStorage.getItem('email'),
    authorized:window.localStorage.getItem('flag') === 'true' ? true :'',
    Token:window.localStorage.getItem('Token'),
  }
  return obj;
}



export async function logout() {
  window.localStorage.clear(); 
  window.localStorage.setItem('flag',false) 
  return true
  // return firebaseAuth.signOut().then(() => true)
}
