import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Menu, Dropdown} from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch }) => {

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  

  const menu = (
    <Menu selectable={false}>
      <Menu.Item key="1">
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />, {window.localStorage.getItem('email') || 'Anonymous'}
        </strong>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">
        <div>
          {/* <strong> */}
          <FormattedMessage id="topBar.profileMenu.role" />:{window.localStorage.getItem('roleName')}
          {/* </strong> */}
          {/* {user.email || '—'} */}
          {/* <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
          </strong>
          {user.phone || '—'} */}
        </div>
      </Menu.Item>
      {/* <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={e => e.preventDefault()}>
          <i className={`${styles.menuIcon} fe fe-user`} />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </a>
      </Menu.Item>
      <Menu.Divider /> */}
      <Menu.Item key="2">
        <a href="#" onClick={logout}>
          <i className={`${styles.menuIcon} fe fe-log-out`} />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <span style={{color:'white'}}>{window.localStorage.getItem('buName')}</span>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
