import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Dashboards
  {
    path: '/home',
    Component: lazy(() => import('pages/Nills-and-Abbas/home')),
    exact: true,
  },

  // Work Orders

  {
    path: '/work-order',
    Component: lazy(() => import('pages/Nills-and-Abbas/work-orders/work-order')),
    exact: true,
  },
  {
    path: '/work-order-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/work-orders/work-order-update')),
    exact: true,
  },
  {
    path: '/wo-calender',
    Component: lazy(() => import('pages/Nills-and-Abbas/work-orders/wo-caleder')),
    exact: true,
  },
  {
    path: '/wo-map',
    Component: lazy(() => import('pages/Nills-and-Abbas/work-orders/wo-map')),
    exact: true,
  },

  // roles
  {
    path: '/roles',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/roles')),
    exact: true,
  },
  {
    path: '/roles-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/roles/roles-update')),
    exact: true,
  },

  // Equipments

  {
    path: '/equipment',
    Component: lazy(() => import('pages/Nills-and-Abbas/eqipments/equipment')),
    exact: true,
  },
  {
    path: '/equipment-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/eqipments/equipment/equipment-update')),
    exact: true,
  },
  {
    path: '/spare-parts',
    Component: lazy(() => import('pages/Nills-and-Abbas/eqipments/spareparts')),
    exact: true,
  },
  {
    path: '/spareparts-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/eqipments/spareparts/spareparts-update')),
    exact: true,
  },
  {
    path: '/brand',
    Component: lazy(() => import('pages/Nills-and-Abbas/eqipments/brand')),
    exact: true,
  },
  {
    path: '/brand-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/eqipments/brand/brand-update')),
    exact: true,
  },
  {
    path: '/classification',
    Component: lazy(() => import('pages/Nills-and-Abbas/eqipments/classification')),
    exact: true,
  },
  {
    path: '/classification-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/eqipments/classification/classification-update'),
    ),
    exact: true,
  },

  // customer

  {
    path: '/customer',
    Component: lazy(() => import('pages/Nills-and-Abbas/customer')),
    exact: true,
  },
  {
    path: '/customer-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/customer/customer-update')),
    exact: true,
  },

  // Setup
  {
    path: '/business-unit',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/business-unit')),
    exact: true,
  },
  {
    path: '/businessUnit-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/business-unit/businessUnit-update')),
    exact: true,
  },
  {
    path: '/email-template',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/email-template')),
    exact: true,
  },
  {
    path: '/email-template-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/email-template/email-update')),
    exact: true,
  },
  {
    path: '/fseg',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/fseg')),
    exact: true,
  },
  {
    path: '/fseg-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/fseg/fseg-update')),
    exact: true,
  },
  {
    path: '/skills',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/skills')),
    exact: true,
  },
  {
    path: '/skills-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/skills/skills-update')),
    exact: true,
  },
  {
    path: '/price-list',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/price-list')),
    exact: true,
  },
  {
    path: '/price-list-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/price-list/price-list-update')),
    exact: true,
  },
  {
    path: '/tax',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/tax')),
    exact: true,
  },
  {
    path: '/tax-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/tax/tax-update')),
    exact: true,
  },
  {
    path: '/tax-category',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/tax-category')),
    exact: true,
  },
  {
    path: '/tax-category-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/tax-category/tax-category-update')),
    exact: true,
  },
  {
    path: '/users',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/users')),
    exact: true,
  },
  {
    path: '/users-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/users/users-update')),
    exact: true,
  },
  {
    path: '/warehouse',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/warehouse')),
    exact: true,
  },
  {
    path: '/warehouse-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/warehouse/warehouse-update')),
    exact: true,
  },
  {
    path: '/workflow-setup',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/workflow-setup')),
    exact: true,
  },
  {
    path: '/workflow-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/workflow-setup/workflow-update')),
    exact: true,
  },
  {
    path: '/workorder-type',
    Component: lazy(() => import('pages/Nills-and-Abbas/setup/workorder-type')),
    exact: true,
  },
  {
    path: '/workordertype-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/setup/workorder-type/workordertype-update'),
    ),
    exact: true,
  },
  // Request
  {
    path: '/feedbackreq',
    Component: lazy(() => import('pages/Nills-and-Abbas/requests/feedback-request')),
    exact: true,
  },
  {
    path: '/feedbackreq-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/requests/feedback-request/feedbackreq-update'),
    ),
    exact: true,
  },
  {
    path: '/quotationreq',
    Component: lazy(() => import('pages/Nills-and-Abbas/requests/quotation-request')),
    exact: true,
  },
  {
    path: '/quotationreq-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/requests/quotation-request/quotation-requpdate'),
    ),
    exact: true,
  },
  {
    path: '/reschedulereq',
    Component: lazy(() => import('pages/Nills-and-Abbas/requests/reschedule-request')),
    exact: true,
  },
  {
    path: '/reschedulereq-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/requests/reschedule-request/reschedulereq-update'),
    ),
    exact: true,
  },
  {
    path: '/sparepartreq',
    Component: lazy(() => import('pages/Nills-and-Abbas/requests/sparepart-request')),
    exact: true,
  },
  {
    path: '/sparepartreq-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/requests/sparepart-request/sparepartreq-update'),
    ),
    exact: true,
  },

  // Reports
  {
    path: '/equipment-report',
    Component: lazy(() => import('pages/Nills-and-Abbas/reports/equipemnt-report')),
    exact: true,
  },
  {
    path: '/spi-report',
    Component: lazy(() => import('pages/Nills-and-Abbas/reports/spi-report')),
    exact: true,
  },
  {
    path: '/wi-report',
    Component: lazy(() => import('pages/Nills-and-Abbas/reports/wi-report')),
    exact: true,
  },
  {
    path: '/wo-summary',
    Component: lazy(() => import('pages/Nills-and-Abbas/reports/wo-summary')),
    exact: true,
  },

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },

  // Sales orders
  /* {
    path: '/sales-orders',
    Component: lazy(() => import('pages/Nills-and-Abbas/sales/sales-orders')),
    exact: true,
  }, */
  /* {
    path: '/sales-orders-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/sales/sales-orders/sales-orders-update')),
    exact: true,
  }, */
  {
    path: '/sales-order',
    Component: lazy(() => import('pages/Nills-and-Abbas/sales/sales-order')),
    exact: true,
  },
  {
    path: '/sales-order-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/sales/sales-order/sales-order-update')),
    exact: true,
  },
  {
    path: '/sales-order-email-template',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/sales/sales-order/sales-order-email-template'),
    ),
    exact: true,
  },
  {
    path: '/sales-invoice',
    Component: lazy(() => import('pages/Nills-and-Abbas/sales/sales-invoice')),
    exact: true,
  },
  {
    path: '/sales-invoice-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/sales/sales-invoice/sales-invoice-update')),
    exact: true,
  },
  {
    path: '/goods-shipment',
    Component: lazy(() => import('pages/Nills-and-Abbas/sales/goods-shipment')),
    exact: true,
  },
  {
    path: '/goods-shipment-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/sales/goods-shipment/goods-shipment-update'),
    ),
    exact: true,
  },
  {
    path: '/goods-receipt',
    Component: lazy(() => import('pages/Nills-and-Abbas/sales/goods-receipt')),
    exact: true,
  },
  {
    path: '/goods-receipt-update',
    Component: lazy(() => import('pages/Nills-and-Abbas/sales/goods-receipt/goods-receipt-update')),
    exact: true,
  },

  // Purchase orders
  {
    path: '/purchase-orders',
    Component: lazy(() => import('pages/Nills-and-Abbas/purchase/purchase-orders')),
    exact: true,
  },
  {
    path: '/purchase-orders-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/purchase/purchase-orders/purchase-orders-update'),
    ),
    exact: true,
  },
  {
    path: '/purchase-invoice',
    Component: lazy(() => import('pages/Nills-and-Abbas/purchase/purchase-invoice')),
    exact: true,
  },
  {
    path: '/purchase-invoice-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/purchase/purchase-invoice/purchase-invoice-update'),
    ),
    exact: true,
  },
  {
    path: '/supplier-categoery',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/purchase/supplier-categoery'),
    ),
    exact: true,
  },
  {
    path: '/supplier-categoery-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/purchase/supplier-categoery/supplier-categoery-update'),
    ),
    exact: true,
  },
  {
    path: '/service-retypes',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/work-orders/service-retypes'),
    ),
    exact: true,
  },
  {
    path: '/service-retypes-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/work-orders/service-retypes/service-retypes-update'),
    ),
    exact: true,
  },
  {
    path: '/defects-ftypes',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/work-orders/defects-ftypes'),
    ),
    exact: true,
  },
  {
    path: '/defects-ftypes-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/work-orders/defects-ftypes/defects-ftypes-update'),
    ),
    exact: true,
  },
  {
    path: '/supplier',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/purchase/supplier'),
    ),
    exact: true,
  },
  {
    path: '/supplier-update',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/purchase/supplier/supplier-update'),
    ),
    exact: true,
  },
  {
    path: '/batchgroup',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/GlobalConfiguration/batchGroup'),
    ),
    exact: true,
  },
  {
    path: '/batchregister',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/GlobalConfiguration/batchRegister'),
    ),
    exact: true,
  },
  {
    path: '/batchscheduler',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/GlobalConfiguration/batchScheduler'),
    ),
    exact: true,
  },
  {
    path: '/batchsequence',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/GlobalConfiguration/batchSequence'),
    ),
    exact: true,
  },
  {
    path: '/configurations',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/GlobalConfiguration/configurations'),
    ),
    exact: true,
  },
  {
    path: '/references',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/GlobalConfiguration/references'),
    ),
    exact: true,
  },
  {
    path: '/approval',
    Component: lazy(() =>
      import('pages/Nills-and-Abbas/ApprovalManagement/approvalNotifications'),
    ),
    exact: true,
  },
  //
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/home" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
